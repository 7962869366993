import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/users',
    title: 'users',
    icon: 'mdi mdi-account',
    class: '',
    extralink: false,
    permissions: ['ListUsers'],
    submenu: []
  },
  {
    path: '/news',
    title: 'newsletter',
    icon: 'mdi mdi-newspaper-variant',
    class: '',
    extralink: false,
    permissions: ['ListPanel-Newsletter'],
    submenu: []
  },
  {
    path: '',
    title: 'orders',
    icon: 'mdi mdi-layers',
    class: 'has-arrow',
    extralink: false,
    permissions: ['ListOrders', 'ListPartner-Orders'],
    submenu: [
      {
        path: '/orders/partners',
        title: 'partner_orders',
        icon: 'mdi mdi-view-list',
        class: '',
        extralink: false,
        permissions: ['ListPartner-Orders'],
        submenu: []
      },
      {
        path: '/orders',
        title: 'all_orders',
        icon: 'mdi mdi-view-list',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['ListOrders']
      },
      {
        path: '/orders/amazon',
        title: 'all_order_amazon',
        icon: 'mdi mdi-view-list',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['ListOrders']
      },
      {
        path: '/orders/audios',
        title: 'audio_orders',
        icon: 'mdi mdi-view-list',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['ListOrders']
      },
      /*
      {
        path: '/orders/devices',
        title: 'device_orders',
        icon: 'mdi mdi-view-list',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['ListOrders']
      },
      {
        path: '/orders/subscriptions',
        title: 'subscriptions',
        icon: 'mdi mdi-view-list',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['ListOrders']
      },
      */
      {
        path: '/orders/reset',
        title: 'reset_orders',
        icon: 'mdi mdi-view-list',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['ListOrders']
      },
      {
        path: '/orders/errors',
        title: 'error_orders',
        icon: 'mdi mdi-alert-box',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['ListOrders']
      },
    ]
  },
  {
    path: '',
    title: 'incidents',
    icon: 'mdi mdi-bookmark-plus-outline',
    class: 'has-arrow',
    extralink: false,
    permissions: ['ListIncidents'],
    submenu: [
      {
        path: '/tickets',
        title: 'incident_list',
        icon: 'mdi mdi-ticket',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['ListIncidents']
      },
      {
        path: '/incidents/reasons',
        title: 'incident_types',
        icon: 'mdi mdi-book-multiple',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['ListIncidents']
      },
      {
        path: '/incidents/solutions',
        title: 'solutions',
        icon: 'mdi mdi-check-all',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['ListIncidents']
      },
    ]
  },
  {
    path: '',
    title: 'packs',
    icon: 'mdi mdi-package-variant-closed',
    class: 'has-arrow',
    extralink: false,
    permissions: ['ListPacks'],
    submenu: [
      {
        path: '',
        title: 'create',
        icon: 'mdi mdi-equal-box',
        class: 'has-arrow',
        extralink: false,
        permissions: ['CreateSinglePack'],
        submenu: [
          {
            path: '/packs/rush',
            title: 'rush',
            icon: '',
            class: '',
            extralink: false,
            permissions: ['CreateSinglePack'],
            submenu: [],
          },
          {
            path: '/packs/express',
            title: 'express',
            icon: '',
            class: '',
            extralink: false,
            permissions: ['CreateSinglePack'],
            submenu: [],
          },
          {
            path: '/packs/single',
            title: 'standard',
            icon: '',
            class: '',
            extralink: false,
            permissions: ['CreateSinglePack'],
            submenu: [],
          },
          {
            path: '/packs/multiples',
            title: 'multiples',
            icon: '',
            class: '',
            extralink: false,
            permissions: ['CreateSinglePack'],
            submenu: [],
          },
        ],
      },
      {
        path: '',
        title: 'review',
        icon: 'mdi mdi-file-find',
        class: 'has-arrow',
        extralink: false,
        permissions: ['ListPacks'],
        submenu: [
          {
            path: '/packs/rush/list',
            title: 'rush',
            icon: '',
            class: '',
            extralink: false,
            permissions: ['ListPacks'],
            submenu: []
          },
          {
            path: '/packs/express/list',
            title: 'express',
            icon: '',
            class: '',
            extralink: false,
            permissions: ['ListPacks'],
            submenu: []
          },
          {
            path: '/packs/single/list',
            title: 'standard',
            icon: '',
            class: '',
            extralink: false,
            permissions: ['ListPacks'],
            submenu: []
          },
        ]
      },
      {
        path: '/packs/automation',
        title: 'scheduler',
        icon: 'mdi mdi-calendar-clock',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['GetOrder']
      },
      {
        path: '/packs/settings',
        title: 'scheduler_config',
        icon: 'mdi mdi-cog-outline',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['GetOrder']
      },
    ]
  },
  {
    path: '',
    title: 'products',
    icon: 'mdi mdi-cart-outline',
    class: 'has-arrow',
    extralink: false,
    permissions: ['ListProducts'],
    submenu: [
      {
        path: '/ecom/subscriptions',
        title: 'subscriptions',
        icon: 'mdi mdi-account',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['ListProducts']
      },
      {
        path: '/ecom/packs',
        title: 'packs',
        icon: 'mdi mdi-package-variant-closed',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['ListProducts']
      },
      {
        path: '/ecom/products',
        title: 'books',
        icon: 'mdi mdi-cards-variant',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['ListProducts']
      },
      {
        path: '/ecom/products-per-language',
        title: 'books_by_language',
        icon: 'mdi mdi-cards-variant',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['ListProducts']
      },
      {
        path: '/ecom/product-extras',
        title: 'extras',
        icon: 'mdi mdi-cart-plus',
        class: '',
        extralink: false,
        submenu: [],
        permissions: 'ListProducts'
      },
      {
        path: '/ecom/audio-books',
        title: 'audio_books',
        icon: 'mdi mdi-music-note',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['ListProducts']
      },
      {
        path: '/ecom/upsells',
        title: 'upsells',
        icon: 'mdi mdi-package-variant-closed',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['ListProducts']
      },
      {
        path: '/ecom/devices',
        title: 'devices',
        icon: 'mdi mdi-package-variant-closed',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['ListProducts']
      },
      {
        path: '/ecom/questionnaires',
        title: 'personalization',
        icon: 'mdi mdi-cards-variant',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['CreateProduct']
      },
      {
        path: '/ecom/categories',
        title: 'categories',
        icon: 'mdi mdi-shape',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['GetCategorie']
      },
      // {
      //   path: '/ecom/cart',
      //   title: 'Cart',
      //   icon: 'mdi mdi-cart',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      // {
      //   path: '/ecom/edit',
      //   title: 'Edit Products',
      //   icon: 'mdi mdi-cart-plus',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      // {
      //   path: '/ecom/details',
      //   title: 'Product Details',
      //   icon: 'mdi mdi-camera-burst',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      /* {
        path: '/ecom/orders',
        title: 'Orders',
        icon: 'mdi mdi-chart-pie',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/ecom/checkout',
        title: 'Checkout',
        icon: 'mdi mdi-clipboard-check',
        class: '',
        extralink: false,
        submenu: []
      } */
    ]
  },
  /*
  {
    path: '',
    title: 'Stocks',
    icon: 'mdi mdi-package-variant',
    class: 'has-arrow',
    extralink: false,
    permissions: ['GetStock'],
    submenu: [
      {
        path: '/stocks',
        title: 'Vista General',
        icon: 'mdi mdi-chart-arc',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['GetStock']
      },
      {
        path: '/stocks/items',
        title: 'Stocks',
        icon: 'mdi mdi-package-variant-closed',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['GetStock']
      }
    ]
  },
  */
  {
    path: '',
    title: 'cms',
    icon: 'mdi mdi-creation',
    class: 'has-arrow',
    extralink: false,
    permissions: ['GetCm'],
    submenu: [
      {
        path: '/cms',
        title: 'cms',
        icon: 'mdi mdi-creation',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['GetCm']
      },
      {
        path: '/cms/info',
        title: 'documentation',
        icon: 'mdi mdi-file-document',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['GetCm']
      }
    ]
  },
  {
    path: '',
    title: 'reports',
    icon: 'mdi mdi-finance',
    class: 'has-arrow',
    extralink: false,
    permissions: ['GetReport'],
    submenu: [
      {
        path: '/reports',
        title: 'dashboard',
        icon: 'mdi mdi-finance',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['GetReport']
      },
      {
        path: '/reports/csv',
        title: 'csv',
        icon: 'mdi mdi-file-document',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['GetReport']
      },
    ]
  },
  {
    path: '',
    title: 'discounts',
    icon: 'mdi mdi-gift',
    class: 'has-arrow',
    extralink: false,
    permissions: ['ListPromotions'],
    submenu: [
      {
        path: '/promotions',
        title: 'discount_list',
        icon: 'mdi mdi-view-list',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['ListPromotions']
      },
      {
        path: '/promotions/create',
        title: 'create_discount',
        icon: 'mdi mdi-wallet-giftcard',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['ListPromotions']
      },
    ]
  },
  /*
  {
    path: '',
        title: 'mails',
    icon: 'mdi mdi-email',
    class: 'has-arrow'  ,
    extralink: false,
    permissions: ['ListOrders'],
    submenu: [
      {
        path: '/notifications/custom',
                title: 'custom_email',
        icon: 'mdi mdi-email',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['ListOrders']
      },
    ]
  },
  */
  {
    path: '/settings/referrals',
    title: 'dugul_coin',
    icon: 'mdi mdi-bitcoin',
    class: '',
    extralink: false,
    submenu: [],
    permissions: ['ListSettings']
  },
  {
    path: '',
    title: 'affiliates',
    icon: 'mdi mdi-handshake-outline',
    class: 'has-arrow',
    extralink: false,
    permissions: ['ListPartners'],
    submenu: [
      {
        path: '/affiliates',
        title: 'affiliates',
        icon: 'mdi mdi-account-cash',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['ListPartners']
      },
      {
        path: '/affiliates/keywords',
        title: 'keywords',
        icon: 'mdi mdi-text-search',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['ListPartners']
      },
      {
        path: '/affiliates/search',
        title: 'affiliate_search',
        icon: 'mdi mdi-magnify',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['ListPartners']
      },
      {
        path: '/affiliates/metrics',
        title: 'metrics',
        icon: 'mdi mdi-google-analytics',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['ListPartners']
      },
    ]
  },
  {
    path: '',
    title: 'settings',
    icon: 'mdi mdi-cog-outline',
    class: 'has-arrow',
    extralink: false,
    permissions: ['ListProvince'],
    submenu: [
      {
        path: '/settings/countries',
        title: 'countries',
        icon: 'mdi mdi-flag',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['ListProvince']
      },
      {
        path: '/settings/bulk',
        title: 'excel_update',
        icon: 'mdi mdi-refresh',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['CreatePrice']
      },
      {
        path: '/settings/printer-prices',
        title: 'printer_prices',
        icon: 'mdi mdi-printer',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['CreatePrice']
      },
      {
        path: '/settings/pdf-config/',
        title: 'pdf_config',
        icon: 'mdi mdi-cog-outline',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['GetOrder']
      },
      {
        path: '/settings/printer-config/',
        title: 'printer_config',
        icon: 'mdi mdi-cog-outline',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['GetOrder']
      },
      {
        path: '/settings/upsell-config/',
        title: 'upsell_config',
        icon: 'mdi mdi-cog-outline',
        class: '',
        extralink: false,
        submenu: [],
        permissions: ['GetOrder']
      },
    ]
  },
  // {
  //   path: '',
  //   title: 'Extra',
  //   icon: 'mdi mdi-gradient',
  //   class: 'has-arrow',
  //   extralink: false,
  //   permissions: ['GetOrder'],
  //   submenu: [
  //     {
  //       path: '/extras/calendar',
  //       title: 'Calendar',
  //       icon: 'mdi mdi-calendar',
  //       class: '',
  //       extralink: false,
  //       submenu: [],
  //       permissions: ['GetOrder']
  //     },
  //     {
  //       path: '/extras/feedback',
  //       title: 'Feedback',
  //       icon: 'mdi mdi-message-alert',
  //       class: '',
  //       extralink: false,
  //       submenu: [],
  //       permissions: ['GetOrder']
  //     }
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Cards',
  //   icon: 'mdi mdi-credit-card-multiple',
  //   class: 'has-arrow',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/cards/basiccards',
  //       title: 'Basic Cards',
  //       icon: 'mdi mdi-layers',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/cards/customcards',
  //       title: 'Custom Cards',
  //       icon: 'mdi mdi-credit-card-scan',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'Extra Components',
  //   icon: 'mdi mdi-cube-send',
  //   class: 'has-arrow',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/extra-component/toastr',
  //       title: 'Toastr',
  //       icon: 'mdi mdi-poll',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/extra-component/upload',
  //       title: 'File Upload',
  //       icon: 'mdi mdi-arrow-up-box',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/extra-component/editor',
  //       title: 'Editor',
  //       icon: 'mdi mdi-dns',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/extra-component/dragndrop',
  //       title: 'Drag n Drop',
  //       icon: 'mdi mdi-arrow-expand-all',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'Widgets',
  //   icon: 'mdi mdi-chemical-weapon',
  //   class: 'has-arrow',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/widgets/apps',
  //       title: 'Widget Apps',
  //       icon: 'mdi mdi-comment-processing-outline',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/widgets/data',
  //       title: 'Widget Data',
  //       icon: 'mdi mdi-calendar',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'UI',
  //   icon: 'mdi mdi-dots-horizontal',
  //   class: 'nav-small-cap',
  //   extralink: true,
  //   submenu: []
  // },
  // {
  //   path: '',
  //   title: 'UI Elements',
  //   icon: 'mdi mdi-widgets',
  //   class: 'has-arrow',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/ui/accordion',
  //       title: 'Accordion',
  //       icon: 'mdi mdi-equal',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/alert',
  //       title: 'Alert',
  //       icon: 'mdi mdi-message-bulleted',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/carousel',
  //       title: 'Carousel',
  //       icon: 'mdi mdi-view-carousel',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/dropdown',
  //       title: 'Dropdown',
  //       icon: 'mdi mdi-arrange-bring-to-front',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/modal',
  //       title: 'Modal',
  //       icon: 'mdi mdi-tablet',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/pagination',
  //       title: 'Pagination',
  //       icon: 'mdi mdi-backburger',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/poptool',
  //       title: 'Popover & Tooltip',
  //       icon: 'mdi mdi-image-filter-vintage',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/progressbar',
  //       title: 'Progressbar',
  //       icon: 'mdi mdi-poll',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/rating',
  //       title: 'Ratings',
  //       icon: 'mdi mdi-bandcamp',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/tabs',
  //       title: 'Tabs',
  //       icon: 'mdi mdi-sort-variant',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/timepicker',
  //       title: 'Timepicker',
  //       icon: 'mdi mdi-calendar-clock',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/buttons',
  //       title: 'Button',
  //       icon: 'mdi mdi-toggle-switch',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'Charts',
  //   icon: 'mdi mdi-dots-horizontal',
  //   class: 'nav-small-cap',
  //   extralink: true,
  //   submenu: []
  // },
  // {
  //   path: '',
  //   title: 'Charts',
  //   icon: 'mdi mdi-image-filter-tilt-shift',
  //   class: 'has-arrow',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/charts/chartjs',
  //       title: 'Chart Js',
  //       icon: 'mdi mdi-svg',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/charts/chartistjs',
  //       title: 'Chartist Js',
  //       icon: 'mdi mdi-blur',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/charts/ngxchart',
  //       title: 'Ngx Charts',
  //       icon: 'mdi mdi-blur',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'Pages',
  //   icon: 'mdi mdi-dots-horizontal',
  //   class: 'nav-small-cap',
  //   extralink: true,
  //   submenu: []
  // },
  // {
  //   path: '',
  //   title: 'Authentication',
  //   icon: 'mdi mdi-account-circle',
  //   class: 'has-arrow',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/auth/login',
  //       title: 'Login',
  //       icon: 'mdi mdi-account-key',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/auth/404',
  //       title: '404',
  //       icon: 'mdi mdi-alert-outline',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'Timeline',
  //   icon: 'mdi mdi-apple-safari',
  //   class: 'has-arrow',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/timeline/left',
  //       title: 'Left Timeline',
  //       icon: 'mdi mdi-clock-fast',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/timeline/right',
  //       title: 'Right Timeline',
  //       icon: 'mdi mdi-clock-end',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/timeline/center',
  //       title: 'Center Timeline',
  //       icon: 'mdi mdi-clock-in',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'Menu Levels',
  //   icon: 'mdi mdi-notification-clear-all',
  //   class: 'has-arrow',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '',
  //       title: 'Second Level',
  //       icon: 'mdi mdi-octagram',
  //       class: '',
  //       extralink: true,
  //       submenu: []
  //     },
  //     {
  //       path: '',
  //       title: 'Second Child',
  //       icon: 'mdi mdi-octagram',
  //       class: 'has-arrow',
  //       extralink: false,
  //       submenu: [
  //         {
  //           path: '',
  //           title: 'Third 1.1',
  //           icon: 'mdi mdi-playlist-plus',
  //           class: '',
  //           extralink: false,
  //           submenu: []
  //         },
  //         {
  //           path: '',
  //           title: 'Third 1.2',
  //           icon: 'mdi mdi-playlist-plus',
  //           class: '',
  //           extralink: false,
  //           submenu: []
  //         }
  //       ]
  //     }
  //   ]
  // }
];
